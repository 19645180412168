import TomSelect from "tom-select";
import $ from "jquery";

const brandsSelectBox = $('#brands-select-box');
const modelsSelectBox = $('#models-select-box');
const yearsSelectBox = $('#years-select-box')

const brandsSelect = new TomSelect("#brands",{
  plugins: {
    remove_button:{
      title:'Verwijder merk',
    }
  },
  persist: false,
  render: {
    no_results: function (data, escape) {
      return '<div class="no-results">Geen merk gevonden..</div>';
    },
  },
  onItemAdd: function(values) {
    searchModels(values);
  },
  onDelete: function(values, event) {
    brandsSelect.focus();
    brandsSelectBox.removeClass('disabled');
    // Model Select
    modelSelect.removeOption('', true)
    modelSelect.clearOptions();
    $('#models').val('').empty().append('<option value="">Selecteer een model</option>');
    modelSelect.sync();
    modelSelect.close();


    modelsSelectBox.addClass('disabled');

    // Year Select
    yearsSelect.clear();
    yearsSelectBox.addClass('disabled');
  }
});

const modelSelect = new TomSelect("#models",{
  plugins: {
    remove_button:{
      title:'Verwijder model',
    }
  },
  persist: false,
  render: {
    no_results: function (data, escape) {
      return '<div class="no-results">Geen model gevonden..</div>';
    },
  },
  onItemAdd: function(values) {
    searchGenerations(values);
  },
  onDelete: function(values, event) {
    modelSelect.focus();
    yearsSelect.clear();
  }
});

const yearsSelect = new TomSelect("#years",{
  plugins: {
    remove_button:{
      title:'Verwijder generatie',
    }
  },
  persist: false,
  render: {
    no_results: function (data, escape) {
      return '<div class="no-results">Geen generatie gevonden..</div>';
    },
  },
  onItemAdd: function(values) {

  }
});


// Search for models
function searchModels($brandID) {
  $.ajax({
    url: '/actions/search-module/search/get-models',
    type: 'GET',
    data: {
      brand: $brandID
    },
    success: function(models) {
      brandsSelectBox.addClass('disabled');
      modelsSelectBox.removeClass('disabled');

      $('#models').empty().append('<option value="">Selecteer een model</option>');

      // Fill the dropdown
      $.each(models, function(index, model) {
        $('#models').append('<option value="' + model.id + '">' + model.name + '</option>');
      });

      modelSelect.clearOptions();
      modelSelect.addOption(models.map(model => ({
        value: model.id,
        text: model.name
      })));
      modelSelect.refreshOptions();
    },
    error: function(xhr, status, error) {
      console.log(xhr.responseText);
      console.error('Error:', error);
    }
  });
}


function searchGenerations($modelID) {
  $.ajax({
    url: '/actions/search-module/search/get-build-years',
    type: 'GET',
    data: {
      model: $modelID
    },
    success: function(models) {
      modelsSelectBox.addClass('disabled');
      yearsSelectBox.removeClass('disabled');

      console.log(models);

      $('#years').empty().append('<option value="">Selecteer een generatie</option>');

      // Fill the dropdown
      $.each(models, function(index, model) {
        $('#years').append('<option value="' + model.id + '">' + model.name + '</option>');
      });

      yearsSelect.clearOptions();
      yearsSelect.addOption(models.map(model => ({
        value: model.id,
        text: model.name
      })));
      yearsSelect.refreshOptions();
    },
    error: function(xhr, status, error) {
      console.log(xhr.responseText);
      console.error('Error:', error);
    }
  });
}


// Submit search form
$(document).on('submit', '#search_car_form', function (e) {
  e.preventDefault();


  $.ajax({
    url: '/actions/search-module/search/search-specific',
    type: 'GET',
    data: {
      brand: brandsSelect.getValue(),
      model: modelSelect.getValue(),
      year: yearsSelect.getValue(),
    },
    success: function(data) {
      if (data && data.model && data.model.length > 0) {
        if (data.generation) {
          window.location.replace(`${data.model}?generation=${data.generation}`);
        } else {
          window.location.replace(data.model);
        }
      } else {
        window.location.replace(data.current_site_shop_url);
      }
    },
    error: function(xhr, status, error) {
      console.log(xhr.responseText);
      console.error('Error:', error);
    }
  });
})

$('#search_license_plate_form').submit(function(e) {
  e.preventDefault();

  var licensePlate = $('#license-plate').val().toUpperCase().replace(/\s+/g, '');

  $.ajax({
    url: `https://opendata.rdw.nl/resource/m9d7-ebf2.json?kenteken=${licensePlate}`,
    method: 'GET',
    success: function(data) {
      if (data.length > 0) {
        var vehicle = data[0];
        console.log(vehicle);

        getDataByLicensePlateData(vehicle.merk, vehicle.handelsbenaming);

      } else {
        $('#vehicle-info').html('<p>Geen gegevens gevonden voor dit kenteken.</p>');
      }
    },
    error: function() {
      $('#vehicle-info').html('<p>Er is een fout opgetreden bij het ophalen van de gegevens.</p>');
    }
  });
});


function getDataByLicensePlateData($brand, $model) {
  $.ajax({
    url: '/actions/search-module/search/get-data-by-license-plate-data',
    type: 'GET',
    data: {
      brand: $brand,
      model: $model,
    },
    success: function(data) {
      if (data && data.model.length > 0) {
        window.location.replace(`${data.brand}/${data.model}`);
      } else {
        window.location.replace(data.current_site_shop_url);
      }
    },
    error: function(xhr, status, error) {
      console.log(xhr.responseText);
      console.error('Error:', error);
    }
  });
}
